@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$sideBarWidth: 280px;

html,
body {
  height: auto;
  height: 100%;
}

#dashboar_container {
  height: 100%;
  .sidebar_container {
    transition: all 0.3s ease-in-out;
    width: $sideBarWidth;
    height: 100%;
    background-color: #703e97;
    position: absolute;
    z-index: 9999;
    .user_img {
      background-color: #563d69;
      border-bottom: 1px solid #6c7988;

      svg {
        font-size: 5em;
        color: $light;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        color: $light;
        margin-left: 16px;
      }

      .side_bar_trigger {
        position: absolute;
        font-size: 5px;
        right: -10px;
        background-color: #fff;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover svg {
          color: #005a8f;
        }
        svg {
          color: #0078bf;
        }
      }
    }

    nav {
      .list-group-item {
        background: transparent;
        cursor: pointer;
        padding: 0;
        border: unset;
        a,
        span {
          color: $light;
          width: 100%;
          display: block;
          padding: 0.6em 1em !important;

          &:hover,
          &.active {
            background-color: $primary;
          }
        }
        .second_ul {
          a {
            padding-left: 2rem !important;
          }
          .list-group-item {
            border: 1px solid rgba(0, 0, 0, 0.125);
          }
        }
      }

      .first-ul,
      .second_ul {
        background-color: #7d4ca2 !important;
      }

      .drop {
        background-color: #563d69;
        padding-bottom: 0;
      }
    }

    .logo_container {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 812;
      width: $sideBarWidth;
      .logo_ {
        text-align: center;
        position: relative;
        left: 0;
        right: 0;
        display: block;
        overflow: hidden;
        width: 170px;
        margin: 0 auto;
        margin-bottom: 20px;
        img {
          width: 90%;
          max-width: 90%;
          height: auto;
        }
        .approval-code {
          display: inline-block;
          color: #fff;
          font-size: 10px;
        }
      }
    }
  }

  .content_wrapper {
    flex: 1;
    transition: all 0.3s ease-in-out;
    max-width: 100%;

    .content {
      background-color: #f4f4f4;
      min-height: 100%;
    }
  }

  @media (min-width: 1000px) {
    .content_wrapper {
      margin-left: $sideBarWidth;
    }
  }
}

.side_fixed {
  .sidebar_container {
    position: fixed !important;
    top: 0;
  }
}

.collapse_nav {
  .sidebar_container {
    margin-left: -$sideBarWidth;
    position: absolute;
  }

  .side_bar_trigger {
    right: -20px !important;
    transform: rotate(180deg);
  }

  .content_wrapper {
    margin-left: 0 !important;
  }
}

.profile_dropdown {
  & > a {
    white-space: unset;
    word-break: break-all;
    max-width: 164px;
    padding: 0.5rem 0.3rem;
  }
  a {
    color: #fff !important;
  }
}
