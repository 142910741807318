@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$darkBleu: #2a276e;

* {
  font-family: "Montserrat", sans-serif;
}

body {
  font-size: 14px;
}

a {
  text-decoration: unset;
}

#loading {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(255,255,255,.9);
  z-index: 9999;
  img {
    width: 100px;
  }
}

.bg-dark-bleu {
  background-color: $darkBleu !important;
}

.text-dark-bleu {
  color: $darkBleu !important;
}

.border-dark-bleu {
  border-color: $darkBleu !important;
}

.btn-dark-bleu {
  color: #fff;
  background-color: $darkBleu;
  border-color: $darkBleu;
  &:hover {
    color: #fff !important;
  }
}
