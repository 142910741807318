@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$primary: #2c80c2;

a {
  color: $primary;
}

.top_card,
.card_status {
  color: $primary;
  padding: 20px 20px;
  border: 1px solid #d2cfcf;
  text-align: center;
  border-radius: 5px;
  // box-shadow: 1px 1px 1px rgb(204 204 204 / 83%);
  transition: all 0.5s ease;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: #fff;
  border-bottom: 4px solid #e1e1e1;
  cursor: pointer;
  font-weight: bold;
}

.top_card {
  &:hover {
    border-bottom: 4px solid $primary;
  }

  svg {
    font-size: 34px;
    position: relative;
  }
}

.card_status {
  span {
    width: 70px;
    height: 70px;
    display: inline-block;
    line-height: 70px;
    border-radius: 50%;
    color: #fff;
    font-size: 35px;
  }
}

.course_card {
  text-align: center;
  // box-shadow: 1px 1px 1px rgb(204 204 204 / 83%);
  // border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: block;
  &:hover .card_img {
    transform: scale(1.1);
  }
  .card_img {
    // padding: 38px 5px;
    transition: all 0.3s ease-in-out;
    background: #fff;
  }
  &:hover .course_card_btn {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
  }
  .course_card_btn {
    border-radius: 0;
  }
}

.course_cards {
  border-radius: 8px;
  overflow: hidden;
  .course_cards_header {
    background: $secondary;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
  }

  .course_cards_body {
    height: 300px;
    background: #fff;
    // box-shadow: 1px 1px 1px rgb(204 204 204 / 83%);
    // border: 1px solid #ccc;
    overflow: auto;
  }
}

.help_container {
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #ccc;
}
