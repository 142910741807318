$primary: #2c80c2;

.card {
  overflow: hidden;
  padding: 3px;
  height: 100%;
  background-color: transparent;
  border: unset;
  .card-image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    .card_img_overlay {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      background: transparent
        linear-gradient(
          180deg,
          #ffffff00 0%,
          #f5f5f50a 40%,
          #564066b3 71%,
          #564066 100%
        )
        0% 0% no-repeat padding-box;
      .card-title {
        position: absolute;
        bottom: 0;
        padding: 8px;
        color: #fff;
        font-size: 14px;
      }
      .module_status {
        position: absolute;
        font-size: 12px;
        padding: 3px 10px;
        color: #fff;
        top: 10px;
        right: 10px;
        border-radius: 15px;
        svg {
          height: 16px;
        }
      }
    }
    .card-logo {
      position: absolute;
      bottom: 10px;
      left: 10px;
      height: 40px;
    }
  }
}

#modDetailts {
  max-width: 800px;
  margin: auto;
  .module_details {
    position: relative;
    height: 400px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;

    .duration_badge {
      font-size: 16px;
    }

    .module_details_overlay {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: transparent
        linear-gradient(238deg, #703e9788 0%, #2e343bd1 80%, #2e343b 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.08;
    }

    .module_details_logo {
      position: absolute;
      bottom: 10px;
      left: 10px;
      height: 80px;
    }
  }

  .card-title {
    color: #703e97;
  }
}

.mod_info {
  & > span {
    color: #fff;
    padding: 5px 10px;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 3px;
    background: #2a276e;
    border-radius: 15px;
  }
}

.card-logo,
.module_details_logo {
  display: none;
}
