@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.err {
  border: 1px solid $danger;
}

.times_icon,
.verify_icon {
  color: #f00;
  font-size: 25px;
}

.verify_icon {
  color: #0f0;
}
