.show_edit_user{
    color: #656565;
}

.profile__img__container{
    position: relative;
    max-width: 200px;

    .delete__profile__img{
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 18px;
        position: absolute;
        right: -8px;
        top: -8px;
        width: 20px;
        height: 20px;
        background-color: rgb(206, 77, 77);
        cursor: pointer;
        border-radius: 50%;
    }

    img{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
}