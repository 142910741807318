@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#footer_ {
  border-top: 1px solid #e7e7e7;

  .header_menu_first {
    display: flex;
    align-items: center;
  }

  .header_menu img {
    height: 46px;
  }

  .header_menu img:last-child {
    display: flex;
    margin-left: auto;
  }

  .header_menu a {
    margin-left: 15px;
    text-decoration: unset;
  }

  .footer_disclaimer {
    display: block;
    width: 100%;
    clear: both;
    color: #703e97;
    padding: 4px;
    span {
      display: block;
      color: #999;
      line-height: 22px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768px) {
  #footer_ {
    .header_menu {
      .header_menu_first {
        flex-direction: column;

        div a {
          padding: 30px;
        }

        a {
          margin-left: 0;
        }
      }

      img:last-child {
        margin: auto;
      }
    }
  }
}
