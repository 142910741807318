@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.user_form_container {
  padding: 15px;
  background-color: #fdfcfc;
  border: 1px solid #eee;
  margin-top: 10%;
  margin-bottom: 10%;
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
  max-width: 700px;
  width: 100%;
  margin: 10% auto;
  margin-top: 4%;
  position: relative;

  h4 {
    padding-bottom: 9px;
    border-bottom: 1px solid #eee;
  }
}
