@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#landing_ {
  margin: 1% 0;

  .img_container {
    box-shadow: 2px 2px 2px #999;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    &.last_box:last-child {
      margin-top: 5.5%;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &:hover .absolute-layer {
      background-color: rgba(51, 51, 51, 0.75);
    }

    img {
      max-width: 100%;
      width: 100%;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      height: auto;
    }

    .absolute-layer {
      position: absolute;
      bottom: 20%;
      display: block;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 360px;
      background-color: rgba(51, 51, 51, 0.37);
      padding: 24px 24px;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: -2px;
        right: -2px;
        border-top: 3px solid #0078bf;
        border-right: 3px solid #0078bf;
      }
      &::after {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -2px;
        left: -2px;
        border-bottom: 3px solid #0078bf;
        border-left: 3px solid #0078bf;
      }

      .name-field {
        font-size: 1.3em;
        margin-bottom: 5px;
      }
      .name-field,
      .description-field {
        color: #fff;
      }
    }
  }

  .left_section .absolute-layer {
    background-color: rgba(255, 255, 255, 0.75);
    top: 10%;
    bottom: unset;

    .name-field {
      color: $primary;
    }

    .description-field {
      color: $secondary;
    }
  }

  &:hover .left_section .absolute-layer {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

@media (max-width: 992px) {
  .img_container {
    margin-top: 5.5%;
  }
}
