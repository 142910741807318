@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#navabar_ {
  background: #fff !important;
  border-bottom: 1px solid #e7e7e7;
  .logo {
    max-width: 200px;
    height: auto;
  }

  .welcome_text {
    display: inline-block;
    margin-top: 24px;
    margin-left: 1%;
    color: $primary;
    font-size: 1em;
  }

  .nav-link {
    color: $primary;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header_right {
    align-items: center;

    @media screen and (max-width: 1200px) {
      .nav-item {
        margin: 10px 0;
      }
    }

    .license_container {
      display: flex;
      align-items: center;
      color: $primary;
      align-items: center;
      .license_desc {
        font-size: 16px;
      }
      .license_num {
        margin-left: 20px;
        border: 5px solid $primary;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .progress_container {
    width: 340px;
    margin-left: 20px;
    font-size: 11px;
    text-align: center;
  }
}
